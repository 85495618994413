import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTimes,
    faLock,
    faCog,
    faRoute,
    faStreetView,
    faMapMarkedAlt,
    faShareSquare,
    faSatelliteDish,
    faSyncAlt,
    faBroom,
    faCamera
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from '../../../../components/datagridComp/Tooltip';
import { MapConfig } from '../../utilsMap/mapContext';
import { FaLock } from 'react-icons/fa';
import { getIsCamera } from '../../utilsMap/tools';

library.add(faTimes, faLock, faCog, faRoute, faStreetView, faMapMarkedAlt, faShareSquare, faSatelliteDish, faSyncAlt, faBroom, faCamera);

const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* Espaçamento entre os botões */
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f0f0f0;
  }

  .fa-icon {
    font-size: 24px;
    color: #7f7f7f;
  }
`;




const ToolBarIconsButtonOneVehicle: React.FC = () => {

    const { vehicleSelected, updateConfig } = MapConfig();

    const isCamera = getIsCamera(vehicleSelected?.tipo || '');

    const onIconClick = (action: string) => {
        updateConfig({ actionPressButton: action });
    };
    return (
        <IconButtonContainer>
            {/* Fechar */}
            <Tooltip text="Fechar">
                <IconButton onClick={() => onIconClick('close')} data-tip="Fechar">
                    <FontAwesomeIcon icon="times" className="fa-icon" />
                </IconButton>
            </Tooltip>

            {/* Configurações */}
            <Tooltip text="Configurações">
                <IconButton onClick={() => onIconClick('settings')} data-tip="Configurações">
                    <FontAwesomeIcon icon="cog" className="fa-icon" />
                </IconButton>
            </Tooltip>

            {/* Listar */}
            <Tooltip text="Trajetos">
                <IconButton onClick={() => onIconClick('list')} data-tip="Listar">
                    <FontAwesomeIcon icon="route" className="fa-icon" />
                </IconButton>
            </Tooltip>

            {/* Street View */}
            <Tooltip text="Street View">
                <IconButton onClick={() => onIconClick('streetView')} data-tip="Street View">
                    <FontAwesomeIcon icon="street-view" className="fa-icon" />
                </IconButton>
            </Tooltip>

            {/* Google Maps */}
            <Tooltip text="Google Maps">
                <IconButton onClick={() => onIconClick('map')} data-tip="Google Maps">
                    <FontAwesomeIcon icon="map-marked-alt" className="fa-icon" />
                </IconButton>
            </Tooltip>

            {/* Compartilhar 
            <Tooltip text="Compartilhar">
                <IconButton onClick={() => onIconClick('share')} data-tip="Compartilhar">
                    <FontAwesomeIcon icon="share-square" className="fa-icon" />
                </IconButton>
            </Tooltip>*/}

            {/* Bloquear */}
            <Tooltip text="Bloquear">
                <IconButton onClick={() => onIconClick('lock')} data-tip="Bloquear">
                    <FontAwesomeIcon icon="lock" className="fa-icon" />
                </IconButton>
            </Tooltip>
            {/* Câmera */}
            {isCamera && <Tooltip text="Câmera">
                <IconButton onClick={() => onIconClick('stream')} data-tip="Camera">
                    <FontAwesomeIcon icon={faCamera} className="fa-icon" />
                </IconButton>
            </Tooltip>}
        </IconButtonContainer>
    );
};


export default ToolBarIconsButtonOneVehicle;
