
import { ReactComponent as WayIcon } from '../assets/menu/way.svg';
import { ReactComponent as MapIcon } from '../assets/menu/map.svg';
import { ReactComponent as MaintenanceIcon } from '../assets/menu/tool.svg';
import { ReactComponent as FuelIcon } from '../assets/menu/fuel2.svg';
import { ReactComponent as DashboardIcon } from '../assets/menu/erp.svg';
import { ReactComponent as ReportIcon } from '../assets/menu/report.svg';
import { ReactComponent as CameraIcon } from '../assets/menu/cam-recorder-full.svg';
import { ReactComponent as ExpensesIcon } from '../assets/menu/calculator-icon.svg';
import { ReactComponent as DriverIcon } from '../assets/menu/driver.svg';
import { ReactComponent as SimcardIcon } from '../assets/menu/simcard.svg';
import { ReactComponent as VehiclesIcon } from '../assets/menu/vehicle-inspection.svg';
import { ReactComponent as DeliveryIcon } from '../assets/menu/on-time.svg';
import { ReactComponent as GroupIcon } from '../assets/menu/people.svg';
import { ReactComponent as TravelIcon } from '../assets/menu/delivery-truck.svg';
import { ReactComponent as WorkDayIcon } from '../assets/menu/working-hours.svg';
import { ReactComponent as ClientIcon } from '../assets/menu/public-relation.svg';
import { ReactComponent as BusinessIcon } from '../assets/menu/company.svg';
import { ReactComponent as EquipIcon } from '../assets/menu/circuit.svg';
import { ReactComponent as HelpIcon } from '../assets/menu/help.svg';
import { ReactComponent as CommandIcon } from '../assets/menu/command.svg';
import { ReactComponent as AlertsIcon } from '../assets/menu/notification.svg';
import { ReactComponent as SettingsIcon } from '../assets/menu/settings.svg';
import { ReactComponent as LogoutIcon } from '../assets/menu/logout.svg';

export interface IMenu {
    title: string;
    path: string;
    cred: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const itensAdmin: IMenu[] = [
    { Icon: DashboardIcon, title: 'DASHBOARD', path: '/dashboard', cred: 'menu_dashboard' },
    { Icon: WayIcon, title: 'MAPA', path: '/map', cred: 'menu_mapa' },
    { Icon: MapIcon, title: 'MAPA V1', path: '/mapV8', cred: 'menu_mapa' },
    { Icon: AlertsIcon, title: 'ALERTAS', path: '/alerts', cred: 'menu_alertas' },
    { Icon: ReportIcon, title: 'RELATÓRIO', path: '/report', cred: 'menu_relatorio' },
    { Icon: ClientIcon, title: 'CLIENTES', path: '/clients', cred: 'menu_clientes' },
    { Icon: ExpensesIcon, title: 'FINANCEIRO', path: '/finances', cred: 'finance' },
    { Icon: ExpensesIcon, title: 'FINANCEIRO BETA', path: '/finances_beta', cred: 'finance' },
    { Icon: VehiclesIcon, title: 'VEÍCULOS', path: '/vehicles', cred: 'menu_veiculos' },
    { Icon: EquipIcon, title: 'EQUIPAMENTOS', path: '/equip', cred: 'menu_equip' },
    { Icon: SimcardIcon, title: 'CHIPS', path: '/chips', cred: 'menu_chips' },
    { Icon: GroupIcon, title: 'GRUPOS/USER', path: '/grupos_user', cred: 'menu_grupos' },
    { Icon: BusinessIcon, title: 'EMPRESA', path: '/empresa', cred: 'menu_empresa' },
    { Icon: HelpIcon, title: 'AJUDA', path: '/help', cred: 'menu_help' },
    { Icon: SettingsIcon, title: 'AJUSTES', path: '/settings', cred: 'menu_settings' },

]

const itensMaster: IMenu[] = [
    { Icon: DashboardIcon, title: 'DASHBOARD', path: '/dashboard-max', cred: 'menu_dashboard' },
    { Icon: SimcardIcon, title: 'CHIPS', path: '/chips-admin', cred: 'menu_chips' },
    { Icon: CommandIcon, title: 'COMANDOS', path: '/commands-creator', cred: 'menu_equip' },
    { Icon: SettingsIcon, title: 'AJUSTES', path: '/settings', cred: 'menu_settings' },
]


const itensClient: IMenu[] = [
    { Icon: WayIcon, title: 'MAPA', path: '/map', cred: 'menu_mapa' },
    //{ Icon: MapIcon, title: 'MAPA BETA', path: '/map-beta', cred: 'menu_mapa' },
    { Icon: AlertsIcon, title: 'ALERTAS', path: '/alerts', cred: 'menu_alertas' },
    { Icon: CameraIcon, title: 'CÂMERAS', path: '/stream', cred: 'menu_mapa' },
    { Icon: ReportIcon, title: 'RELATÓRIO', path: '/report', cred: 'menu_relatorio' },
    { Icon: FuelIcon, title: 'ABASTECIMENTO', path: '/fuel', cred: 'menu_abast' },
    { Icon: MaintenanceIcon, title: 'MANUTENÇÃO', path: '/manut', cred: 'menu_manut' },
    { Icon: ExpensesIcon, title: 'DESPESAS', path: '/despesas', cred: 'menu_desp' },
    { Icon: DriverIcon, title: 'MOTORISTAS', path: '/drivers', cred: 'menu_motor' },
    { Icon: WorkDayIcon, title: 'JORNADA', path: '/jornada', cred: 'menu_jornada' },
    { Icon: DeliveryIcon, title: 'ENTREGAS', path: '/delivery', cred: 'menu_entregas' },
    { Icon: TravelIcon, title: 'VIAGENS', path: '/travel', cred: 'menu_viagem' },
    { Icon: VehiclesIcon, title: 'VEÍCULOS', path: '/veiculos_cli', cred: 'menu_veiculoscli' },
    //{ Icon: WayIcon, title: 'CHECKLIST', path: '/checklist', cred: 'menu_checklist' },
    //{ Icon: WayIcon, title: 'INFRAÇÕES', path: '/multas', cred: 'menu_multas' },
    { Icon: GroupIcon, title: 'GRUPOS/USER', path: '/grupos_user', cred: 'menu_grupos' },
    { Icon: HelpIcon, title: 'AJUDA', path: '/help', cred: 'menu_help' },
    { Icon: SettingsIcon, title: 'AJUSTES', path: '/settings', cred: 'menu_settings' },
]

const itensAlwaysShow: IMenu[] = [
    { Icon: LogoutIcon, title: 'SAIR', path: '/logout', cred: 'logout' },
]


export function getItensMenu(level: number, credenciais: any): IMenu[] {
    let menus = level === 3 ? itensClient : level === 2 ? itensAdmin : level === 1 ? itensMaster : []
    let arr = menus.filter(i => {
        if (credenciais[i.cred] === 1) {
            return i
        } else if (credenciais[i.cred] === 2) {
            i.path = './noauth'
            return i
        }
        return null

    })
    arr = arr.concat(itensAlwaysShow)
    return arr

}


