import { httpAxiosV4 } from "../../../gateways/Axios";
import { dtFilter } from "../../ExpensesPage/components/DateFilterDashboard";


export async function getFuelDashboad(payload: any): Promise<IDashboardFuel[]> {
    const { data } = await httpAxiosV4<reqDashboardFuel, IDashboardFuel[]>(//request, response
        `fuel/dashboard`,
        "POST",
        payload
    );
    return data;
}


export interface reqDashboardFuel {
    idvehicle?: number
    iddriver?: number
    ExpenseId?: number
    cercaId?: number
    typeEvt?: string
    status?: string
    dtFilter?: dtFilter
}

export type IDashboardFuel = {
    id_veiculo: number;            // ID do veículo
    placa: string;                 // Placa do veículo
    total_km_rodados: number;      // Total de quilômetros rodados
    media_combustivel: number;     // Média de combustível
    total_litros: number;          // Total de litros abastecidos
    total_gastos: number;          // Total de gastos em abastecimento
    custo_por_km: number;          // Custo por quilômetro rodado
    media_km_por_litro: number;    // Média de quilômetros por litro (eficiência)
    total_abastecimentos: number;  // Total de abastecimentos
};

export type iCategoryFuelResume = {
    category: string;
    total_amount: string;
}

