import { VehicleData } from "../../StreamCam/StreamInterfaces";
import { FilterVehicles } from "../components/FilterVehicles";



export const checkVehiclsHasOnline = (lastUpdate: string) => {
    if (!lastUpdate) return false;
    const lastUpdateDate = new Date(lastUpdate).getTime();
    const now = new Date().getTime();
    const diff = Math.floor((now - lastUpdateDate) / 1000);
    return diff < 3600;
}

export const prepareStatusFilter = (dados: VehicleData[]): FilterVehicles[] => {
    let filter: FilterVehicles[] = [];
    let countOffline = 0;
    let countMove = 0;
    let countBatCut = 0;


    dados.forEach((vehicle) => {
        if (!checkVehiclsHasOnline(vehicle.dtPing)) {
            countOffline++;
        } else {
            if (vehicle.gpsvel > 0)
                countMove++;
            if (Number(vehicle.batveic || 0) < 2)
                countBatCut++;
        }
    });

    filter.push({
        type: 'offline',
        count: countOffline,
        title: 'Offline'
    });

    filter.push({
        type: 'move',
        count: countMove,
        title: 'Em movimento'
    });

    filter.push({
        type: 'batcut',
        count: countBatCut,
        title: 'Corte de bateria'
    });

    return filter
}

export const formateTime = (diffSeconds: number) => {
    const totalMinutos = Math.floor(diffSeconds / 60);
    const dias = Math.floor(totalMinutos / 1440); // 1440 minutos em um dia
    const horas = Math.floor((totalMinutos % 1440) / 60);
    const minutos = totalMinutos % 60;
    const segundos = diffSeconds % 60; // Segundos restantes após minutos

    let resultado = '';

    if (dias > 0) {
        resultado += `${dias}d `;
    }

    if (horas > 0) {
        resultado += `${horas}h `;
    }

    if (minutos > 0) {
        resultado += `${minutos}min `;
    }

    if (segundos > 0) {
        resultado += `${segundos}s`;
    }

    return resultado.trim();
}


export const getIsCamera = (typeDevice: string) => {
    const isCam = ['JC4xx', 'JC450'].includes(typeDevice ?? '');
    return isCam;
}