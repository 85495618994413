import React from 'react';
import { Column } from 'react-data-grid';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { styled } from 'styled-components';
import DataGridComp from '../../../../components/datagridComp/DataGridComp';
import { httpAxiosV4 } from '../../../../gateways/Axios';
import SearchField from '../../../../components/datagridComp/SearchField';


const ContainerTable = styled('div')({
    background: 'white',
    boxShadow: '0px 0 30px rgb(1 41 112 / 15%)',
    borderRadius: '5px',
    margin: '10px',
    height: '450px',
    overflow: 'auto'
});

const SearchAndSelectContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem; 
    padding: 5px;
`;


interface CompProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSelectAlert: (row: any) => void;
}

const ModalListClients: React.FC<CompProps> = ({ isOpen, onRequestClose, onSelectAlert }) => {
    const [rows, setRows] = React.useState<any[]>([]);
    const [searchText, setSearchText] = React.useState<string>('');
    const [selectedRows, setSelectedRows] = React.useState<ReadonlySet<number>>(() => new Set());

    const columns: Column<any>[] = [
        { key: 'id', name: 'Código' },
        { key: 'name', name: 'Nome' },
        { key: 'doc_number', name: 'CNPJ/CPF' }
    ];

    // Função para buscar os dados da API
    async function getListAlertsSetup(): Promise<any> {
        try {
            const { data } = await httpAxiosV4<any, any>(`clients/list-select`, "GET");
            return data;
        } catch (error) {
            console.error('Error fetching client list:', error);
            throw error;
        }
    }

    // Função que realiza a busca inicial dos dados
    async function getDados() {
        try {
            const result = await getListAlertsSetup();
            setRows(result);
        } catch (e) {
            console.error('[ModalListClients]', e);
        }
    }

    // Carrega os dados na montagem do componente
    React.useEffect(() => {
        getDados();
    }, []);

    // Filtragem dos dados baseada no texto de busca
    const filteredRows = React.useMemo(() => {
        if (!searchText) return rows;

        const normalizedSearchText = searchText
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();

        return rows.filter((person) => {
            const normalizedName = person.name
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();
            const normalizedDoc = person.doc_number
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();

            return (
                normalizedName.includes(normalizedSearchText) ||
                normalizedDoc.includes(normalizedSearchText)
            );
        });
    }, [rows, searchText]);

    // Função para atualizar o texto da busca
    const handleSearch = (searchText: string) => {
        setSearchText(searchText);
    };

    // Função que lida com a seleção de uma linha
    const handleRowClick = (row: any) => {
        onSelectAlert(row);
        onRequestClose();
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 800, maxHeight: 600 } }}
            maxWidth="md"
            open={isOpen}
            onClose={onRequestClose}
        >
            <DialogTitle>Lista de Clientes</DialogTitle>
            <DialogContent>
                <SearchAndSelectContainer>
                    <SearchField onSearch={handleSearch} />
                </SearchAndSelectContainer>
                <ContainerTable>
                    <DataGridComp
                        rows={filteredRows}
                        columns={columns}
                        selectedRows={selectedRows}
                        onRowClick={handleRowClick}
                    />
                </ContainerTable>
            </DialogContent>
        </Dialog>
    );
};

export default React.memo(ModalListClients);