


import { MarkerWithLabel } from "@googlemaps/markerwithlabel";
import '../Styles/MapStyles.css';
import { EventsData } from "../repository/MapsRepo";
import { fi } from "date-fns/locale";
import { format } from "date-fns";
import { createRoot } from "react-dom/client";

function createAdvanceMarkerEvents(
    map: google.maps.Map,
    event: EventsData
): google.maps.marker.AdvancedMarkerElement {

    const eventIconsOld: { [key: string]: string } = {
        'ign_on': 'ign_on_dark2.png',
        'ign_off': 'ignOf64.png',
        'earthquake': 'earthquake.png',
        // Adicione mais tipos de eventos e seus respectivos ícones aqui
    };

    const eventIcons = (event: string) => {

        switch (event.toLocaleUpperCase()) {
            case 'IGN':
                return 'evt_ign_dark.png';
            case 'DB_ABRUPT_TURN':
                return 'evt_abrupt_turn_dark.png';
            case 'DB_ACCELERATION':
                return 'evt_acceleration_dark.png';
            case 'DB_BRAKING':
                return 'evt_braking_dark.png';
            case 'DB_SPEEDING':
                return 'evt_70km_dark.png';
            case 'VS_COLLISION':
                return 'evt_collision_dark.png';
            default:
                return 'evt_unknow_dark.png';
        }
    };


    const color = event.status === 'ON' ? 'green' : 'orange';
    const labelContent = `
        <div style="position: relative; width: 40px; height: 40px;">                
                <img src="assets/icons_alerts/${eventIcons(event.sevento)}" style=" z-index: 0; width: 32px; height: 32px; position: absolute; top: 4px; left: 4px;" />
            <div class="custom-label-events">
                ${event.sevento}
            </div>
        </div> 
    `;


    const container = document.createElement("div");
    const root = createRoot(container);
    root.render(<div dangerouslySetInnerHTML={{ __html: labelContent }} />);


    const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: parseFloat(event.lat), lng: parseFloat(event.lng) },
        map: map,
        content: container,
        title: event.sevento,
    });

    const getIconPath = (event: string) => {
        switch (event) {
            case 'ignition_on':
                return '/assets/icons_alerts/ign_on.png';
            case 'ignition_off':
                return '/assets/icons_alerts/ignOff64.png';
            // Adicione mais cases conforme necessário
            default:
                return '/assets/icons_alerts/ign_on_dark.png';
        }
    };
    /*
        const iconPath = getIconPath(event.sevento);
        const marker = new google.maps.Marker({
            position: { lat: parseFloat(event.lat), lng: parseFloat(event.lng) },
            map: map,
            icon: {
                url: iconPath,
                scaledSize: new google.maps.Size(32, 32), // Ajuste o tamanho conforme necessário
            },
            title: "adadad", // Usar título para mostrar o conteúdo do label no hover
        });*/

    // Add click listener to open InfoWindow

    const files = event.files_stream ? event.files_stream.split(',') : null;

    const videoFile1 = files != null && files[0] ? `https://dvr.maxtracer.com.br/playiot/${files[0]}` : null;
    const videoFile2 = files != null && files[1] ? `https://dvr.maxtracer.com.br/playiot/${files[1]}` : null;

    const formattedDate = format(new Date(event.devento), 'dd/MM/yyyy HH:mm:ss');

    let contentInfo =
        `<div style="z-index:99999999; min-width: 250px; background-color: #333; color: white; padding: 10px; border-radius: 10px; ">
             <div style="display: flex; align-items: center;">
                    <div style="position: relative; width: 70px; height: 70px;">                
                        <img src="assets/icons_alerts/${eventIcons(event.sevento)}" style="z-index: 0; width: 64px; height: 64px; position: absolute; top: 4px; left: 4px;" />
                    </div>
                    <div style="margin-left: 10px;">
                        <h3>${event.apelido}</h3>
                        <p>${event.sevento}</p>
                        <p>Data e Hora: ${formattedDate}</p>
                    </div>
                </div>
      `
    contentInfo += videoFile1 ? ` 
                <div>  
                    <video controls autoplay  width="380" >
                            <source src="${videoFile1}" type="video/mp4" />
                            Your browser does not support the video tag.
                    </video>   
                </div>` : '<div></div>';

    contentInfo += videoFile2 ? ` 
                <div>  
                    <video controls autoplay  width="380" >
                            <source src="${videoFile2}" type="video/mp4" />
                            Your browser does not support the video tag.
                    </video>   
                </div>` : '<div></div>';
    contentInfo += `</div>`

    const infoWindow = new google.maps.InfoWindow({
        content: contentInfo,
    });

    marker.addListener('click', () => {
        infoWindow.open(map, marker);
    });


    return marker;
}

export default createAdvanceMarkerEvents;