import { httpAxiosV4 } from "../../../gateways/Axios";


const version = 'v3'

export async function getAlerts(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqAlerts, any>(
        `${version}/alerts-evt-open`,
        "POST",
        payload
    );
    return data;
}
export async function getAlertsRead(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<reqAlerts, any>(
        `${version}/alerts-evt-read`,
        "GET",
        payload
    );
    return data;
}
export async function getRulesAlerts(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `${version}/alerts-rules-user`,
        "GET"
    );
    return data;
}
export async function setReadAlerts(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `${version}/alerts-setread`,
        "POST",
        payload
    );
    return data;
}

export async function getListAlertsSetup(): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `${version}/alerts-setup`,
        "GET"
    );
    return data;
}
export async function RegisterAlertsInServer(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `${version}/register-alert`,
        "POST",
        payload
    );
    return data;
}
export async function UpdateAlert(payload: any): Promise<any> {
    const { data } = await httpAxiosV4<any, any>(
        `${version}/update-alert`,
        "POST",
        payload
    );
    return data;
}



export interface itemUpAlert {
    descr: string
    alerta_id: number
    points: number
    priority: number
    param1: string
    notif_client: boolean
    notif_driver: boolean
    points_active: boolean
    speed: number
    tipo: string
}

export interface reqAlerts {
    idclient: number
    idvehicle?: number
    iddriver?: number
    alertId?: number
    cercaId?: number
    typeEvt?: string
}



export interface itemAlerts {
    id: number
    idclient: number;
    iddriver: number;
    idvehicle: number;
    idevt: number;
    id_alert: number;
    idcerca: number;
    priority: number;
    points: number;
    tipo: string;
    evt: string;
    param1: string;
    avatar: string;
    title: string;
    descr: string;
    lat: string;
    lng: string;
    obs: string | null;
    create_at: string;
    dtevt_at: string;
    DriverAvatar: string;
    DriverName: string;
    vehicle: string;
    icone: string;
}

export interface itemRuleAlert {
    id: number;
    title: string;
    icon: string;
    type_alert: string;
    alerta_id: number;
    id_alert_setup: number;
    iduser: number;
    idempresa: number;
    descr: string;
    tipo: string;
    param1: number;
    valor: null | number;
    notif_driver: number;
    notif_client: number;
    IsRangerWork: number;
    semana: string;
    priority: number;
    points: number;
    points_active: number;
    nalertas: number;
    intervalo: number;
    status: number;
    modo: number;
    nivel: number;
    sons: number;
    evento: number;
    inFavActive: number;
    inFavAlert: number;
    inFavTimer: number;
    inFavTol: number;
    outFavActive: number;
    outFavAlert: number;
    outFavTimer: number;
    outFavTol: number;
    delete_at: null | string;
    update_at: null | string;
    create_at: null | string;
    num_subscribes: number;
    num_cercas: number;
    num_vehicles: number;
};
