import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../redux/userSlice';
import { VehicleData } from '../../StreamCam/StreamInterfaces';
import { EventsData, getApiEventos, getApiOneVehicle, getApiTrajetos, getApiVehicleMonthChart, getVehiclesByStream, TrajectData } from '../repository/MapsRepo';
import { styled } from 'styled-components';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { FaMapMarkedAlt, FaStreetView } from 'react-icons/fa';

import Sidebar from '../../../components/datagridComp/SideBar';
import OpcoesMap from '../components/OpcoesMap';
import OpcoesHistory from '../components/OpcoesHistory';
import { useConfig } from '../../../Config/configContext';
import { formatDateDayJs } from '../../../utils/Converter';
import IconButtonsMonitor from '../components/IconsButtonMonitor';
import { MapConfig } from '../utilsMap/mapContext';
import { useGoogleMap } from '../../../GoogleMaps/GoogleMapProvider';
import { ALL_VEHICLES, HISTORY_VEHICLE, ONE_VEHICLE, OPTIONS_TRAJETOS, SETTINGS_MAP, STREAM_VEHICLE } from '../utilsMap/ConstMaps';
import ModalComp from '../../../components/datagridComp/ModalComp';
import CmdBlockComp from '../../ComandsCreator/views/CmdBlockComp';


import createAdvanceMarker from '../utilsMap/createAdvanceMarker';
import TabHistory from './TabHistory';
import VehicleMapComp from '../components/VehicleMapComp';
import VehicleMapCompSmall from '../components/VehicleMapCompSmall';
import createAdvanceMarkerEvents from '../utilsMap/createAdvanceMarkerEvents';
import SearchFieldV2 from '../../../components/datagridComp/SearchFieldV2';
import FilterList, { FilterVehicles } from '../components/FilterVehicles';
import { checkVehiclsHasOnline, getIsCamera, prepareStatusFilter } from '../utilsMap/tools';
import VehicleMonthChart from '../components/GraphicsMap/VehicleMonthChart';
import { set } from 'date-fns';
import VehiclePhotoComp from '../components/VehiclePhotoComp';
import StreamMapVehicle from '../components/StreamMapVehicle';

import { FixedSizeList, FixedSizeList as List, ListChildComponentProps, VariableSizeList } from 'react-window';

let intervalMap: any
let isInitMpp = true

const SummaryVehicles = styled.div`
    font-size: 12px;
    color: #666;
    padding-left: 5px;
    display: flex;
    align-items: start;
    width: 100%;
`;
// Container geral do layout
const LayoutContainer = styled.div`
  display: flex;
  height: 88vh;
`;

const LeftContainer = styled.div<{ isExpanded: boolean }>`
  width: ${({ isExpanded }) => (isExpanded ? '410px' : '328px')};
  padding-top: 5px;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 88vh;
  align-items: center;
  overflow: auto;
`;


const VehiclesContainer = styled.div`
 display: flex;
  gap   : 5px;
  height: 88vh;
  flex-direction: column;
  align-items: center;
  overflow: auto;
`;

const ContainerOverFlow = styled.div`
    overflow: auto;
    height: 88vh;
    `;

const ContainerMaps = styled.div`
  flex-grow: 1;
  padding: 5px;
  transition: margin-left 0.3s; /*Ajusta a margem dependendo do estado do menu */
`;

const MapContainer = styled.div`
  height: 100%; /* Garante que o mapa ocupe toda a altura do contêiner pai */
  width: 100%; /* Garante que o mapa ocupe toda a largura do contêiner pai */
  position: relative; /* Necessário para posicionar os marcadores */
`;

var actionConfig = ALL_VEHICLES
var idVehicleSel = 0

const LoadingSpinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 18px;
  font-weight: bold;
  color: #555;
`;

const MapTab: React.FC = () => {

    const { LoadEventsInReportMap, LoadTrajectsInReportMap, speedLimitShowPositions, dateStartFilterMap, dateEndFilterMap } = useConfig();
    const { vehicleSelected, modeActive, actionPressButton, updateConfig } = MapConfig();
    //----------------Maps
    const map = useGoogleMap();
    const mapContainerRef = useRef<HTMLDivElement>(null);

    const markersRef = useRef<google.maps.Marker[]>([]);
    const markersEventsRef = useRef<google.maps.marker.AdvancedMarkerElement[]>([]);
    const polyLineRef = useRef<google.maps.Polyline[]>([]);
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const [markerOneVehicle, setMarkerOneVehicle] = React.useState<google.maps.marker.AdvancedMarkerElement>();

    const markersVehicleRef = useRef<{ [id: string]: google.maps.marker.AdvancedMarkerElement }>({});
    const clustererRef = useRef<MarkerClusterer | null>(null);
    //--------------
    const { user } = useSelector(selectUser);
    const [rows, setRows] = React.useState<VehicleData[]>([])
    const [filteredRows, setFilteredRows] = React.useState<VehicleData[]>([])
    const [trajects, setTrajects] = React.useState<TrajectData[]>([])
    const [eventsData, setEventsData] = React.useState<EventsData[]>([])
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [configFilters, setConfigFilters] = React.useState<FilterVehicles[]>([])
    const [filterVehicles, setFilterVehicles] = React.useState<string[]>([]);
    const [findVehicles, setFindVehicles] = React.useState<string>('');
    const [vehicleMonthChart, setVehicleMonthChart] = React.useState<any[]>([]);
    const [isLoadingGraphics, setIsLoadingGraphics] = React.useState<boolean>(false);

    //sidebar 
    const [sideContent, setSideContent] = React.useState<string>();
    const handleCloseSideBar = () => setSideContent(undefined);
    //modal
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

    async function getAllVehicles() {
        const payload = {
            idcliente: user.idcliente,
            idempresa: user.idempresa,
            nivel: user.nivel
        }
        const result = await getVehiclesByStream(payload);
        let resp: VehicleData[] = result;
        setRows(resp)

        /*let vehicles = resp.map((item) => {
            item.statusFilter = calculeStatusFilter(item);
            return item
        })*/

        // console.log("Filters", findVehicles, filterVehicles)
        // if (findVehicles.length === 0 && filterVehicles.length === 0) {
        //     console.log("Update Filtered Rows")
        //     setFilteredRows(resp)
        // }

    }


    async function getVehicleMonthChart(idvehicle: number) {
        setIsLoadingGraphics(true)
        const result = await getApiVehicleMonthChart(idvehicle);
        setVehicleMonthChart(result)
        setIsLoadingGraphics(false)

    }

    useEffect(() => {
        if (rows.length === 0) return
        setConfigFilters(prepareStatusFilter(rows))
        handleSearch()
    }, [rows])

    async function getOneVehicle() {
        if (!idVehicleSel) {
            console.log("Vehicle Selected is null", idVehicleSel)
            return
        }
        const payload = {
            idvehicle: idVehicleSel,
        }
        const result = await getApiOneVehicle(payload);
        let resp: VehicleData = result;
        if (resp) {
            updateConfig({ vehicleSelected: resp })
        }

    }

    const handleSearch_remove = (searchText: string) => {
        const filtered = rows.filter((person) =>
            Object.values(person).some((value) => {
                const normalizedValue = String(value).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                const normalizedSearchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            })
        );
        setFilteredRows(filtered);
    };

    const handleSearch = () => {
        let searchText = findVehicles
        const filtered = rows.filter((vehicle: VehicleData) => {
            const normalizedSearchText = searchText
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLowerCase();

            // Verifica se algum valor do veículo corresponde ao texto de busca
            const matchesSearchText = Object.values(vehicle).some((value) => {
                const normalizedValue = String(value)
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .toLowerCase();
                return normalizedValue.includes(normalizedSearchText);
            });

            let isOnline = checkVehiclsHasOnline(vehicle.dtPing);
            let isMoving = vehicle.gpsvel > 0 && isOnline;
            let isBatteryCut = Number(vehicle.batveic || 0) < 2;

            const matchesStatusFilter =
                filterVehicles.length === 0 || // Se nenhum filtro foi passado, retorna todos
                (filterVehicles.includes("offline") && !isOnline) ||
                (filterVehicles.includes("move") && isMoving) ||
                (filterVehicles.includes("batcut") && isBatteryCut);

            return matchesSearchText && matchesStatusFilter;

        })


        setFilteredRows(filtered);
    };

    useEffect(() => {
        handleSearch();
    }, [findVehicles]);

    useEffect(() => {
        handleSearch();
    }, [filterVehicles]);



    useEffect(() => {
        clearMap()
        actionConfig = modeActive
        getDados()
    }, [modeActive]);



    async function getTrajetos(idVehicle: number) {
        const dtStart = formatDateDayJs(dateStartFilterMap)
        const dtEnd = formatDateDayJs(dateEndFilterMap)

        const payload = {
            dtStart,
            dtEnd,
            idVehicle
        };
        const result = await getApiTrajetos(payload);
        let resp: TrajectData[] = result;
        setTrajects(resp)
        updateConfig({ countPositions: resp.length })
    }
    async function getEventos(idVehicle: number) {
        const dtStart = formatDateDayJs(dateStartFilterMap)
        const dtEnd = formatDateDayJs(dateEndFilterMap)

        const payload = {
            dtStart,
            dtEnd,
            idVehicle
        };
        const result = await getApiEventos(payload);
        let resp: EventsData[] = result;
        setEventsData(resp)
        updateConfig({ countEvents: resp.length })
    }

    function getColorBasedOnSpeed(speed: number): string {
        if (speed <= 50) return "#1100ff"; // Verde para velocidade baixa
        if (speed > 50 && speed < 80) return "#ff7300"; // Amarelo para velocidade média
        return "#FF0000"; // Vermelho para velocidade alta
    }

    async function getDados() {
        console.log("Get Dados", actionConfig)
        switch (actionConfig) {
            case ALL_VEHICLES:
                await getAllVehicles()
                break;
            case ONE_VEHICLE:
            case STREAM_VEHICLE:
                await getOneVehicle()
                break;
            default:
                console.log("Action not found")
                break;
        }
    }

    function getHistory() {
        updateConfig({ modeActive: HISTORY_VEHICLE })
        const idVehicle = vehicleSelected?.idveiculo || 0;
        if (LoadEventsInReportMap) getEventos(idVehicle)
        if (LoadTrajectsInReportMap) getTrajetos(idVehicle)
    }
    function setFormFlag() {
        updateConfig({ modeActive: HISTORY_VEHICLE })
        const idVehicle = vehicleSelected?.idveiculo || 0;
        if (LoadEventsInReportMap) getEventos(idVehicle)
        if (LoadTrajectsInReportMap) getTrajetos(idVehicle)
    }
    function setStream() {
        idVehicleSel = vehicleSelected?.idveiculo || 0
        updateConfig({ modeActive: STREAM_VEHICLE, vehicleSelected: vehicleSelected })
    }

    const CtrlInterval = () => {

        console.log("Start interval Maps")
        intervalMap = setInterval(() => {
            getDados()
            console.log("getDados", vehicleSelected)
        }, 15000)

    }

    React.useEffect(() => {
        if (isInitMpp) {
            isInitMpp = false;
            CtrlInterval()
            getDados()
        }
        //componentUnAmount()
        return () => {
            console.log("Destruct MapBeta")
            clearInterval(intervalMap)
            isInitMpp = true;
            clearMap()
        }
    }, [])

    useEffect(() => {
        console.log('Map IN Maps Open')
        if (map && mapContainerRef.current) {
            // Associa o mapa ao contêiner específico desta tela
            map.setOptions({
                center: { lat: -15.7801, lng: -47.9292 }, // Ajuste conforme necessário
                zoom: 4,
            });

            // Move o mapa para o contêiner correto
            mapContainerRef.current.appendChild(map.getDiv());
            google.maps.event.trigger(map, 'resize');
            // Initialize MarkerClusterer
            clustererRef.current = new MarkerClusterer({ map });

        }
    }, [map]);

    useEffect(() => {
        const vehicles = filteredRows;
        if (mapContainerRef.current && vehicles.length > 0) {
            const bounds = new google.maps.LatLngBounds();

            // Mapeia veículos pelo ID para facilitar comparações
            const newMarkers: { [id: string]: google.maps.marker.AdvancedMarkerElement } = {};

            // Função para atualizar markers de veículos
            const updateMarkers = () => {
                vehicles.forEach((vehicle) => {
                    const existingMarker = markersVehicleRef.current[vehicle.id];

                    if (existingMarker) {
                        // Se o veículo já existe no mapa, verificamos se a posição mudou
                        if (existingMarker.position?.lat !== Number(vehicle.gpslat) || existingMarker.position?.lng !== Number(vehicle.gpslng)) {
                            // Atualiza a posição do marker se mudou
                            existingMarker.position = new google.maps.LatLng(Number(vehicle.gpslat), Number(vehicle.gpslng));
                        }
                        // Mantemos esse marker no novo conjunto
                        newMarkers[vehicle.id] = existingMarker;
                        bounds.extend(existingMarker.position!);
                    } else {
                        // Se o veículo é novo, criamos um novo marker
                        const newMarker = createAdvanceMarker(map!, vehicle, (item: any) => {
                            handleSelectVehicle(item);
                        });
                        newMarkers[vehicle.id] = newMarker;
                        bounds.extend(newMarker.position!);
                    }
                });

                // Remove markers de veículos que não estão mais presentes
                Object.keys(markersVehicleRef.current).forEach((id) => {
                    if (!newMarkers[id]) {
                        const marker = markersVehicleRef.current[id];
                        marker.map = null; // Remove o marker do mapa
                    }
                });

                // Atualiza o objeto de referência de markers com o novo estado
                markersVehicleRef.current = newMarkers;

                // Atualiza os markers no clusterer
                if (clustererRef.current) {
                    clustererRef.current.clearMarkers();
                    clustererRef.current.addMarkers(Object.values(newMarkers));
                }

                // Ajusta os limites do mapa para incluir todos os markers
                if (isFirstLoad) {
                    map!.fitBounds(bounds);
                    setIsFirstLoad(false);
                }
            };

            updateMarkers();
        }
    }, [filteredRows, isFirstLoad]);

    // useEffect(() => {
    //     const vehicles = filteredRows;
    //     if (mapContainerRef.current && vehicles.length > 0) {
    //         const bounds = new google.maps.LatLngBounds();

    //         // Mapeia veículos pelo ID para facilitar comparações
    //         const newMarkers: { [id: string]: google.maps.marker.AdvancedMarkerElement } = {};

    //         // Função para atualizar markers de veículos
    //         const updateMarkers = () => {
    //             vehicles.forEach((vehicle) => {
    //                 const existingMarker = markersVehicleRef.current[vehicle.id];

    //                 if (existingMarker) {
    //                     // Se o veículo já existe no mapa, verificamos se a posição mudou
    //                     if (existingMarker.position?.lat !== Number(vehicle.gpslat) || existingMarker.position?.lng !== Number(vehicle.gpslng)) {
    //                         // Remove o marker anterior do cluster e do mapa
    //                         if (clustererRef.current) {
    //                             clustererRef.current.removeMarker(existingMarker);
    //                         }
    //                         existingMarker.map = null; // Remove o marker do mapa

    //                         // Cria um novo marker na nova posição
    //                         const updatedMarker = createAdvanceMarker(map!, vehicle, (item: any) => {
    //                             handleSelectVehicle(item);
    //                         });

    //                         // Atualiza a posição no novo conjunto de markers
    //                         newMarkers[vehicle.id] = updatedMarker;
    //                         bounds.extend(updatedMarker.position!);

    //                         // Adiciona o marker atualizado ao cluster
    //                         if (clustererRef.current) {
    //                             clustererRef.current.addMarker(updatedMarker);
    //                         }
    //                     } else {
    //                         // Se a posição não mudou, mantemos o marker existente
    //                         newMarkers[vehicle.id] = existingMarker;
    //                     }
    //                 } else {
    //                     // Se o veículo é novo, criamos um novo marker
    //                     const newMarker = createAdvanceMarker(map!, vehicle, (item: any) => {
    //                         handleSelectVehicle(item);
    //                     });

    //                     newMarkers[vehicle.id] = newMarker;
    //                     bounds.extend(newMarker.position!);

    //                     // Adiciona o novo marker ao cluster
    //                     if (clustererRef.current) {
    //                         clustererRef.current.addMarker(newMarker);
    //                     }
    //                 }
    //             });

    //             // Remove markers de veículos que não estão mais presentes
    //             Object.keys(markersVehicleRef.current).forEach((id) => {
    //                 if (!newMarkers[id]) {
    //                     const markerToRemove = markersVehicleRef.current[id];
    //                     markerToRemove.map = null; // Remove o marker do mapa
    //                     if (clustererRef.current) {
    //                         clustererRef.current.removeMarker(markerToRemove); // Remove o marker do cluster
    //                     }
    //                 }
    //             });

    //             // Atualiza o objeto de referência de markers com o novo estado
    //             markersVehicleRef.current = newMarkers;

    //             // Ajusta os limites do mapa para incluir todos os markers
    //             if (isFirstLoad) {
    //                 map!.fitBounds(bounds);
    //                 setIsFirstLoad(false);
    //             }
    //         };

    //         updateMarkers();
    //     }
    // }, [filteredRows, isFirstLoad]);

    useEffect(() => {
        if (map && vehicleSelected) {
            clearMap()
            if (markerOneVehicle) markerOneVehicle.map = null
            const marker = createAdvanceMarker(map!, vehicleSelected, () => { });
            setMarkerOneVehicle(marker);
            map?.setCenter(marker.position!);
        }
    }, [vehicleSelected]);

    useEffect(() => {
        if (map && trajects.length > 0) {
            const bounds = new google.maps.LatLngBounds();
            const path = trajects.map(t => ({ lat: parseFloat(t.lat), lng: parseFloat(t.lng) }));

            const line = new google.maps.Polyline({
                map: map,
                path,
                geodesic: true,
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 4,
            });

            polyLineRef.current.push(line);

            for (const point of path) {
                bounds.extend(point);
            }
            map.fitBounds(bounds);

            // Adicionando marcadores com InfoWindow
            trajects.forEach((t) => {
                const position = { lat: parseFloat(t.lat), lng: parseFloat(t.lng) };
                const colorMarker = getColorBasedOnSpeed(t.speed);
                if (t.speed > speedLimitShowPositions) {
                    const marker = new google.maps.Marker({
                        position,
                        map: map,
                        icon: {
                            path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 3,
                            fillColor: colorMarker,
                            fillOpacity: 0.8,
                            rotation: t.dir,
                            strokeColor: colorMarker,
                            strokeWeight: 2,
                        },
                    });
                    markersRef.current.push(marker);


                    const infoWindow = new google.maps.InfoWindow({
                        content: `
                        <div>
                            <p><strong>Velocidade:</strong> ${t.speed} km/h</p>
                            <p><strong>Latitude:</strong> ${t.lat}</p>
                            <p><strong>Longitude:</strong> ${t.lng}</p>
                            <button onclick="window.open('https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${t.lat},${t.lng}', '_blank')">
                                <i class="${FaStreetView}"></i> Street View
                            </button>
                            <button onclick="window.open('https://www.google.com/maps/search/?api=1&query=${t.lat},${t.lng}', '_blank')">
                                <i class="${FaMapMarkedAlt}"></i> Google Maps
                            </button>
                        </div>
                    `,
                    });

                    marker.addListener('click', () => {
                        infoWindow.open(map, marker);
                    });

                    marker.setMap(map);
                }
            });
        }
    }, [trajects]);

    useEffect(() => {
        if (map && eventsData.length > 0) {

            // Adicionando marcadores com InfoWindow
            eventsData.forEach((t) => {
                if (t.sevento === 'OA_FILE_UPLOADED') return
                const marker = createAdvanceMarkerEvents(map!, t);
                markersEventsRef.current.push(marker);
            });
        }
    }, [eventsData]);


    const handleSelectVehicle = (vehicle: VehicleData) => {
        clearMap()
        idVehicleSel = vehicle.idveiculo
        updateConfig({ modeActive: ONE_VEHICLE, idVehicleSel: vehicle.idveiculo, vehicleSelected: vehicle })
        map!.setCenter({ lat: parseFloat(vehicle.gpslat), lng: parseFloat(vehicle.gpslng) });
        map!.setZoom(15);
        getVehicleMonthChart(vehicle.idveiculo)
    }

    const clearMap = () => {
        Object.keys(markersVehicleRef.current).forEach((id) => {
            const marker = markersVehicleRef.current[id];
            marker.map = null;
        });
        markersRef.current.forEach(marker => marker.setMap(null));
        markersRef.current = [];
        markersEventsRef.current.forEach(marker => marker.map = null);
        markersEventsRef.current = [];
        polyLineRef.current.forEach(line => line.setMap(null));
        polyLineRef.current = [];
        if (clustererRef.current) {
            clustererRef.current.clearMarkers();
        }
    }

    const handleClose = () => {
        clearMap()
        idVehicleSel = 0
        if (markerOneVehicle) markerOneVehicle.map = null
        updateConfig({ vehicleSelected: null, modeActive: ALL_VEHICLES })
        setIsFirstLoad(true)
    };


    const handleIconClick = (action: string) => {
        switch (action) {
            case 'close':
                handleClose()
                break;
            case 'lock':
                setIsModalOpen(true)
                break;
            case 'settings':
                setSideContent(OPTIONS_TRAJETOS)
                break;
            case 'settings-map':
                setSideContent(SETTINGS_MAP)
                break;
            case 'list':
                getHistory()
                break;
            case 'streetView':
                window.open(`https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${vehicleSelected?.gpslat},${vehicleSelected?.gpslng}`, '_blank');
                break;
            case 'map':
                window.open(`https://www.google.com/maps/search/?api=1&query=${vehicleSelected?.gpslat},${vehicleSelected?.gpslng}`, '_blank');
                break;
            case 'share':
                console.log('Compartilhar ação');
                break;
            case 'monitoring':
                //setMonitor()
                break;
            case 'clear':
                clearMap()
                break;
            case 'update':
                clearMap()
                getHistory()
                break;
            case 'update-map':
                getDados()
                break;
            case 'setform-flag':
                setFormFlag()
                break;
            case 'stream':
                setStream()
                break;


            default:
                console.log('Ação desconhecida');
        }
        updateConfig({ actionPressButton: null })
    };

    useEffect(() => {
        if (actionPressButton)
            handleIconClick(actionPressButton)
    }, [actionPressButton]);

    const menuTop = (
        <IconButtonsMonitor onIconClick={handleIconClick} mode={modeActive} />
    )

    const getItemSize = (index: number) => {
        let iddriver = filteredRows[index].DriverName
        if (iddriver) {
            return 115; // Altura maior para veículos específicos
        }
        return 98; // Altura padrão
    };

    return (
        <>
            <LayoutContainer>
                <LeftContainer isExpanded={modeActive === STREAM_VEHICLE}>
                    {!vehicleSelected &&
                        (<>
                            <SummaryVehicles>Exibindo {filteredRows.length} de {rows.length} Veículos</SummaryVehicles>
                            <SearchFieldV2 onSearch={setFindVehicles} searchTerm={findVehicles} />
                            <FilterList filters={configFilters} onFilterSelect={setFilterVehicles} />
                        </>)}
                    <VehiclesContainer>
                        {vehicleSelected ?
                            <>
                                {modeActive === ONE_VEHICLE &&
                                    (<>
                                        <VehicleMapComp vehicleData={vehicleSelected} />
                                        <VehiclePhotoComp
                                            filename={vehicleSelected.vehicle_photo}
                                            idclient={user.idcliente}
                                            idempresa={vehicleSelected.id}
                                            idvehicle={vehicleSelected.idveiculo}
                                        />
                                        {isLoadingGraphics ? <LoadingSpinner>Carregando...</LoadingSpinner> : <VehicleMonthChart data={vehicleMonthChart} />}
                                    </>
                                    )}
                                {modeActive === HISTORY_VEHICLE &&
                                    <TabHistory
                                        EventsItens={eventsData}
                                        PositionsItens={trajects} />}
                                {modeActive === STREAM_VEHICLE &&
                                    (<>
                                        <VehicleMapComp vehicleData={vehicleSelected} />
                                        <StreamMapVehicle />
                                    </>)}


                            </>
                            : filteredRows.length ?
                                <VariableSizeList
                                    height={516}
                                    itemCount={filteredRows.length}
                                    itemSize={getItemSize}
                                    width={329}
                                >
                                    {({ index, style }) => (
                                        <div style={style} onClick={() => handleSelectVehicle(filteredRows[index])}>
                                            <VehicleMapCompSmall vehicleData={filteredRows[index]} />
                                        </div>
                                    )}
                                </VariableSizeList> : <div>Nenhum veiculo</div>
                        }
                    </VehiclesContainer>
                </LeftContainer>
                <ContainerMaps>
                    <MapContainer ref={mapContainerRef}></MapContainer>
                </ContainerMaps>
            </LayoutContainer>


            <Sidebar isOpen={sideContent === OPTIONS_TRAJETOS} onClose={handleCloseSideBar} title='Opções de Trajetos'>
                <OpcoesHistory />
            </Sidebar>
            <Sidebar isOpen={sideContent === SETTINGS_MAP} onClose={handleCloseSideBar} title='Configurações do Mapa'>
                <OpcoesMap />
            </Sidebar>
            {(isModalOpen && vehicleSelected) && (
                <ModalComp title="Comando de Bloqueio" subtitle="" onClose={() => { setIsModalOpen(false) }}>
                    <CmdBlockComp
                        onClose={() => { setIsModalOpen(false) }}
                        vehicleData={vehicleSelected}
                    />
                </ModalComp>
            )}
        </>
    );
};

export default MapTab;
