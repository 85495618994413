import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaUpload, FaEdit, FaExclamationTriangle, FaCheck, FaTimes, FaSpinner, FaExchangeAlt, FaWrench } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid'; // Importa a função uuid v4
import { iDetailsVehicleData, updatePhotoVehicle } from '../repository/MapsRepo';
import { useNotifications } from '../../../Notifications/NotificationContext';
import ClientIcon from './ClientIcon';
import DeviceIcon from './DeviceIcon';
import { httpAxiosV4 } from '../../../gateways/Axios';
import ConfirmationDialog from '../../../components/datagridComp/ConfirmationDialog';
import ModalComp from '../../../components/datagridComp/ModalComp';
import LoadingIndicator from '../../../components/datagridComp/LoadingComp';
import ModalListEquip from './modals/ModalListEquip';

// Interfaces
interface InfoDeviceMapsProps {
  item: iDetailsVehicleData;
  onSync?: () => void;
}

// Styled Components
const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 6px;
  width: 315px;
  font-family: Arial, sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: height 0.3s ease;
  position: relative;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Title = styled.h2`
  font-size: 14px;
  font-weight: bold;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Subtitle = styled.h3`
  font-size: 12px;
  color: #666;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BoxHeader = styled.div`
  margin-left: 8px;
`;

const Divider = styled.div`
  border-bottom: 1px solid #c5c5c5;
  margin: 1px 0;
`;

const BoxActions = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 6px;
    `;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  margin: 0 10px;
  color: #555;
  font-weight: 600;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  gap: 4px; /* Espaço entre o ícone e o texto */

  &:hover {
    color: #000;
  }

  svg {
    font-size: 16px; /* Ajusta o tamanho do ícone */
  }
`;

const InfoDeviceMaps: React.FC<InfoDeviceMapsProps> = ({ item, onSync }) => {

  const [isOpenListEquip, setIsOpenListEquip] = useState(false);
  const [isOpenConfirmEquip, setIsOpenConfirmEquip] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado de loading durante o upload
  const { addNotification } = useNotifications();
  const [selectedEquip, setSelectedEquip] = useState<any>(null);



  const handleSelectEquipItem = async (item: any) => {
    setSelectedEquip(item)
  };

  // Função para buscar os dados da API
  async function sendActionAlterVehicle(payload: any): Promise<any> {
    try {
      setIsLoading(true);
      const { data } = await httpAxiosV4<any, any>(`equip/alter-equip`, "PUT", payload);
      return data;
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }

  }

  const handleConfirmed = async (reason?: string) => {
    try {
      setIsOpenConfirmEquip(false)
      let payload = {
        previous_equip_id: item.equip_id,
        current_equip_id: selectedEquip.id,
        idveiculo: item.idveiculo,
        current_client_id: item.idcliente,
        vehicle_descr: item.apelido,
        reason: reason || 'Sem justificativa',
      }
      await sendActionAlterVehicle(payload)
      addNotification(
        'Alteração realizada com sucesso',
        `Equipamento imei [${item.imei}], trocado por  ${selectedEquip.imei} (${selectedEquip.equip_producer} / ${selectedEquip.equip_model})`,
        'success'
      );
      onSync?.();
    } catch (error) {
      addNotification(
        'Erro ao realizar a alteração',
        `Erro ao trocar o equipamento imei [${item.imei}] por ${selectedEquip.imei} (${selectedEquip.equip_producer} / ${selectedEquip.equip_model})`,
        'error'
      );
    }
  };



  useEffect(() => {
    if (selectedEquip) {
      setIsOpenConfirmEquip(true);
    }
  }, [selectedEquip]);

  const handleAlterClick = () => {
    setIsOpenListEquip(true);
    setSelectedEquip(null);
  };



  return (
    <>
      <CardContainer>
        <Subtitle>Equipamento (Cód.: {item.equip_id})</Subtitle>
        <Header>
          <DeviceIcon avatar='circuit-board' />
          <BoxHeader>
            <Title>{item.imei}</Title>
            <Subtitle>{item.equip_producer} / {item.equip_model}</Subtitle>
          </BoxHeader>
        </Header>
        <Divider />
        <BoxActions>
          <ActionButton onClick={handleAlterClick} disabled={isLoading}>
            <FaExchangeAlt /> Alterar
          </ActionButton>
          {/*<ActionButton onClick={console.log} disabled={isLoading}>
            <FaWrench /> Instalação
          </ActionButton>*/}
        </BoxActions>
      </CardContainer>
      <CardContainer>
        <Subtitle>Chip (Cód.: {item.chip_id})</Subtitle>
        <Header>
          <DeviceIcon avatar='mobile' />
          <BoxHeader>
            <Title>{item.chip_serial}</Title>
            <Subtitle>{item.chip_provider}</Subtitle>
            <Subtitle>{item.chip_line}</Subtitle>
          </BoxHeader>
        </Header>

        {/*<Divider />
        <BoxActions>
          <ActionButton onClick={console.log} disabled={isLoading}>
            <FaExchangeAlt /> Alterar
          </ActionButton>

        </BoxActions>*/}
      </CardContainer>
      {isOpenListEquip && <ModalListEquip
        onRequestClose={() => { setIsOpenListEquip(false) }}
        isOpen={isOpenListEquip}
        onSelectAlert={handleSelectEquipItem} />}
      {isOpenConfirmEquip && <ModalComp title="Confirmar Alteração" subtitle="" onClose={() => setIsOpenConfirmEquip(false)}>
        <ConfirmationDialog
          type={'QUESTION'}
          requireJustification={true}
          onCancel={() => setIsOpenConfirmEquip(false)}
          onConfirm={handleConfirmed}
          message={`Confirme a alteração do equipamento ${item.imei} (${item.equip_producer} / ${item.equip_model}),
           pelo equipamento ${selectedEquip?.imei} (${selectedEquip.equip_producer} / ${selectedEquip.equip_model})`} />
      </ModalComp>}
      {isLoading && <ModalComp title="Aguarde..." subtitle="" onClose={() => { }}>
        <LoadingIndicator message='Salvando alterações...' />
      </ModalComp>}
    </>
  );
};

export default InfoDeviceMaps;
