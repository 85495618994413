
import React from 'react';
import { useNotifications } from '../../../Notifications/NotificationContext';
import { useSelector } from 'react-redux';
import { SearchAndSelectContainer } from '../style/StyleFuel';
import BarChart, { BarChartData } from '../../../components/graphics/BarCharts';
import { selectUser } from '../../../redux/userSlice';
import DateFilterDashboard, { dtFilter } from '../../ExpensesPage/components/DateFilterDashboard';
import { getFuelDashboad, iCategoryFuelResume, IDashboardFuel, reqDashboardFuel } from '../repository/FuelRepo';
import { styled } from 'styled-components';
import CategoryCardFuel from '../components/CategoryCardFuel';

const ContainerInline = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;  // Alterado de 'start' para 'flex-start' para maior compatibilidade
  gap: 10px;
  flex-wrap: wrap;          // Adicionado para permitir que os elementos quebrem linha
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  min-width: 400px;         // Definir a largura mínima do gráfico
  height: 400px;
  padding: 10px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  margin: 5px;
`;




const DashboardFuelPage: React.FC = () => {

    const { addNotification } = useNotifications();
    const { user } = useSelector(selectUser);
    const [dataDriver, setDataDriver] = React.useState<BarChartData[]>([]);
    const [dataCategory, setDataCategory] = React.useState<BarChartData[]>([]);
    const [dtFilter, setDtFilter] = React.useState<dtFilter>({ lastreg: 1000 });

    const [dataLitros, setDataLitros] = React.useState<BarChartData[]>([]);
    const [dataKm, setDataKm] = React.useState<BarChartData[]>([]);
    const [dataAmount, setDataAmount] = React.useState<BarChartData[]>([]);
    const [dataCostKm, setDataCostKm] = React.useState<BarChartData[]>([]);
    const [dataMediaKmPorLitro, setDataMediaKmPorLitro] = React.useState<BarChartData[]>([]);
    const [dataCategoryResume, setDataCategoryResume] = React.useState<iCategoryFuelResume[]>([]);

    const currencyFormatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });


    async function getFuelByVehicle() {
        try {

            let payload: reqDashboardFuel = {
                dtFilter: dtFilter,
            }
            const result = await getFuelDashboad(payload);
            console.log(result)
            let sumLitros = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.total_litros,
                    id: item.id_veiculo
                }
            })
            let sumKm = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.total_km_rodados,
                    id: item.id_veiculo
                }
            })
            let sumAmount = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.total_gastos,
                    id: item.id_veiculo
                }
            })
            let sumCostKm = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.custo_por_km,
                    id: item.id_veiculo
                }
            })
            let sumMediaKmPorLitro = result.map((item: IDashboardFuel) => {
                return {
                    label: item.placa,
                    value: item.media_km_por_litro,
                    id: item.id_veiculo
                }
            })
            // Calcula os totais acumulados da frota
            let sumCategoryResume = result.reduce((acc: any, item: any) => {
                return {
                    total_km_rodados: acc.total_km_rodados + item.total_km_rodados,
                    total_litros: acc.total_litros + item.total_litros,
                    total_gastos: acc.total_gastos + item.total_gastos,
                    total_abastecimentos: acc.total_abastecimentos + Number(item.total_abastecimentos)
                };
            }, {
                total_km_rodados: 0,
                total_litros: 0,
                total_gastos: 0,
                total_abastecimentos: 0
            });

            // Calcula o custo por km total da frota
            const custoPorKmTotal = sumCategoryResume.total_gastos / sumCategoryResume.total_km_rodados;

            // Calcula a média geral de km/litro da frota
            const mediaKmPorLitroFrota = sumCategoryResume.total_km_rodados / sumCategoryResume.total_litros;

            // Formata os dados para exibir no resumo
            let categ: iCategoryFuelResume[] = [];
            categ.push({ category: 'Total KM Rodados', total_amount: `${sumCategoryResume.total_km_rodados.toFixed(2)} KM` });
            categ.push({ category: 'Total Litros', total_amount: `${sumCategoryResume.total_litros.toFixed(2)} L` });
            categ.push({ category: 'Total Gastos', total_amount: `${currencyFormatter.format(sumCategoryResume.total_gastos)}` });
            categ.push({ category: 'Total Abastecimentos', total_amount: `${Number(sumCategoryResume.total_abastecimentos)}` });
            categ.push({ category: 'Custo por KM Total', total_amount: `${currencyFormatter.format(custoPorKmTotal)}` });
            categ.push({ category: 'Média Geral de KM/Litro', total_amount: `${mediaKmPorLitroFrota.toFixed(2)} KM/L` });

            // Atualiza o estado com os dados formatados
            setDataCategoryResume(categ);

            setDataLitros(sumLitros)
            setDataKm(sumKm)
            setDataAmount(sumAmount)
            setDataCostKm(sumCostKm)
            setDataMediaKmPorLitro(sumMediaKmPorLitro)
        } catch (e) {
            let error = e as Error
            addNotification('', error.message, 'error');
        }
    }



    const getRegistros = () => {
        if (dtFilter.dtStart === undefined || dtFilter.dtEnd === undefined) {
            console.log('Data de inicio e fim não informadas')
            return
        }
        getFuelByVehicle()
    }
    /*
        React.useEffect(() => {
            getRegistros()
        }, [])*/

    React.useEffect(() => {
        getRegistros()
    }, [dtFilter])




    return (
        <>
            <>
                <SearchAndSelectContainer>
                    <DateFilterDashboard onDateChange={setDtFilter} />
                </SearchAndSelectContainer>

            </>
            <CategoryCardFuel categories={dataCategoryResume} />
            <ContainerInline>
                <Container>
                    <h1>Total de Litros</h1>
                    <BarChart data={dataLitros} />
                </Container>
                <Container>
                    <h1>Total Pago </h1>
                    <BarChart data={dataAmount} />
                </Container>
                <Container>
                    <h1>Custo por KM</h1>
                    <BarChart data={dataCostKm} />
                </Container>
                <Container>
                    <h1>Média KM/Litro</h1>
                    <BarChart data={dataMediaKmPorLitro} />
                </Container>
                <Container>
                    <h1>Total de KM Percorrido</h1>
                    <BarChart data={dataKm} />
                </Container>


            </ContainerInline>
        </>
    )

}
export default DashboardFuelPage