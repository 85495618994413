import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppBar, changeMenu } from '../redux/appBarSlice';
import { logout, selectUser } from '../redux/userSlice';
import { HelpCenter, Logout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import '../styles/Appbar.css'
import { DrawerConfig } from '../Drawer/drawerContext';



export default function AppBarComp() {

    const { isOpenMenu, updateConfig } = DrawerConfig();

    const dispatch = useDispatch();
    const { appbar } = useSelector(selectAppBar);
    const { user } = useSelector(selectUser);
    const nav = useNavigate();

    const handleDrawerToggle = () => {
        updateConfig({ isOpenMenu: !isOpenMenu })
    };


    const handleLogout = () => {
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("dados_token");
        window.localStorage.removeItem("pathSelected");
        dispatch(logout())
        nav("/login")
    }
    const handleAlerts = () => {
        nav("/alerts")
    }
    const handleHelp = () => {
        nav("/help")
    }



    return (
        <>
            <div className='max-appbar'>
                <div className='max-toolbar'>
                    <div className='max-iconmenu'>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            edge="start"
                            sx={{
                                marginRight: 5,
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        {user.fantasia}
                    </div>

                    <div className='max-toolbar-itens-right'>
                        {user.name}
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

                            <IconButton size="large" aria-label="show alerts" color="inherit" onClick={handleAlerts}>
                                <Badge badgeContent={0} color="error">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <IconButton size="large" aria-label="show alerts" color="inherit" onClick={handleLogout}>
                                <Logout />
                            </IconButton>
                            <IconButton size="large" aria-label="show alerts" color="inherit" onClick={handleHelp}>
                                <HelpCenter />
                            </IconButton>
                        </Box>
                    </div>
                </div>
            </div>

            {/*<Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" color="secondary" >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerToggle}
                        edge="start"
                        sx={{
                            marginRight: 5,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                    >
                        {user.fantasia}
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" aria-label="show alerts" color="inherit">
                            <Badge badgeContent={0} color="error">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton>
                        <IconButton size="large" aria-label="show alerts" color="inherit" onClick={handleLogout}>
                            <Logout />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
                    </Box>*/}
        </>
    );
}
