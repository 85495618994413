
import './styles/App.css'
import aes from 'crypto-js/aes';
import utf8 from 'crypto-js/enc-utf8';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeUser, logout, selectUser } from './redux/userSlice';
import AppBarComp from './components/AppBarComp'
import { themeLight } from './styles/themes/MuiTheme';
import { ThemeProvider } from '@mui/material/styles';

import RoutesComp from "./routes";
import { selectAppBar } from './redux/appBarSlice';
import { changeCred } from './redux/credSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import AlertComp from './components/AlertComp';
import PanelRightComp from './components/PanelsRight/PanelRightComp';
import WebSocketProvider from './gateways/WebSocket';
import { converteObjLogin } from './utils/Converter';
import Drawer from './Drawer/Drawer';
import { NotificationProvider } from './Notifications/NotificationContext';
import { NotificationContainer } from './Notifications/NotificationContainer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ConfigProvider } from './Config/configContext';
import { GoogleMapProvider } from './GoogleMaps/GoogleMapProvider';
import { DrawerConfig, DrawerProvider } from './Drawer/drawerContext';
import LayoutWithDrawer from './LayoutWithDrawer';


function App() {



  const dispatch = useDispatch();
  const { state } = useLocation();
  const { user } = useSelector(selectUser);
  const { appbar } = useSelector(selectAppBar);
  const nav = useNavigate();

  const CheckLogged = () => {
    let dados_token = window.localStorage.getItem('dados_token') || null
    if (dados_token) {
      try {
        let dados_str = aes.decrypt(dados_token, process.env.REACT_APP_KEY_AES || '').toString(utf8)
        let dados_user = JSON.parse(dados_str)
        dispatch(changeUser(converteObjLogin(dados_user)))
        dispatch(changeCred(dados_user.credentials))
        document.title = "Gestão de Frotas"
        //loading
        if (!window.location.hash.length) {
          let last_page = window.localStorage.getItem('last_page') || '/map'
          nav(last_page)
        }

      } catch (error) {
        console.log('[CheckLogged]', error)
        dispatch(logout())
      }
    } else {
      console.log("dados_token invalid")
      dispatch(logout())
    }
  }

  React.useEffect(() => {
    const onPageLoad = () => {
      CheckLogged()
    };

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  return (
    <DrawerProvider>
      <ConfigProvider>
        <NotificationProvider>
          <NotificationContainer />
          <WebSocketProvider>
            <ThemeProvider theme={themeLight}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ptBR">
                <GoogleMapProvider>

                  <AlertComp />
                  {user && user.isAuthenticated ?
                    <>
                      <AppBarComp />
                      <LayoutWithDrawer />
                      <PanelRightComp />
                    </> : <LoginPage />}
                </GoogleMapProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </WebSocketProvider>
        </NotificationProvider>
      </ConfigProvider>
    </DrawerProvider>
  );
}

export default App;
